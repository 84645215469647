.paragraph {
  font-size:13px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--ion-text-color, #000);
  opacity: 0.75;
  transition:all 0.25s ease;
}
.paragraph:last-child{
  padding-bottom: 20px;
}
.paragraph.checked {
  opacity:0.20;
  text-decoration: line-through;
}
.picture {
  opacity: 1;
  transition:all 0.25s ease;
}
.picture.checked {
  opacity:0.20;
}

.paragraph + .picture{
  margin-top:10px;
}
.picture + .paragraph{
  margin-top:10px;
}

.partTitle{
  font-size:24px;
}