#splashscreen{
  background-image: url('./assets/splashscreen.jpg');
  background-size: cover;
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index:999;
  transition: 0.5s ease all;
}

#splashscreen.hide{
  top:-100vh;
}